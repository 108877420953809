<template>
  <section id="cardSelector">
    <b-row>
      <b-col class="m-2">
        <div style="text-align: justify;">
          <h1>{{ $t('designCard') }}</h1>
          <h5 class="text-primary">
            {{ $t('SelctCard') }}
          </h5>
        </div>
        <b-form-checkbox
          v-if="hasLegacy"
          v-model="isLegacy"
          style="text-align: end;"
          class="mr-0 mt-50"
          switch
        >
          isLegacy
        </b-form-checkbox>
      </b-col>
      <b-col
        class="m-2"
        style="text-align: end"
      >
        <b-button
          variant="success"
          :disabled="!selected"
          @click="seleccionar"
        >
          {{
            $t("common.elegir")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        align-self="baseline"
        style="height:500px"
        class="scroll-list list-group list-group-flush "
      >
        <b-list-group-item
          v-for="res in response"
          :key="res.node.id"
          class="cursor-pointer"
          :active="selected == res.node.id"
          :value="res.node"
          @click="marcar(res.node)"
          @dblclick="marcar(res.node, true)"
        >
          <b-row class=" text-justify align-items-center">
            <b-col cols="3">
              <b-img
                id="avatar"
                rounded="lg"
                :src="res.node.sampleImage"
              />
            </b-col>
            <b-col>
              <b-card-text class="mb-0 font-weight-bold">
                {{ res.node.name }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-col>
      <b-col id="colCard">
        <b-img
          v-if="mostrar"
          fluid
          :src="sampleImage"
          alt="Card image cap"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BListGroupItem,
  BCol,
  BRow,
  BImg,
  BButton,
  BFormCheckbox,
  BCardText,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

export default {
  components: {
    BFormCheckbox,
    BListGroupItem,
    BCardText,
    BCol,
    BRow,
    BImg,
    BButton,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    seleccionado: { type: Object, default: null },
  },
  data() {
    return {
      video: false,
      fileUrl: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      idOrig: null,
      folders: null,
      widthPx: null,
      heightPx: null,
      isLegacy: false,
      hasLegacy: false,
      sampleImage: null,
      response: null,
      name: null,
      mostrar: false,
    }
  },
  watch: {
    isLegacy() {
      this.allCards()
    },
  },
  mounted() {
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode !== 'production') {
      this.hasLegacy = true
    } else {
      this.isLegacy = false
    }
    this.allCards()
  },
  methods: {
    seleccionar() {
      this.$emit('id', { id: this.selected, name: this.name, sampleImage: this.sampleImage })
      // this.$emit('cardData', [this.name, this.sampleImage])
    },
    marcar(node, go = false) {
      this.mostrar = true
      this.sampleImage = node.sampleImage
      this.name = node.name
      this.selected = node.id
      if (go) { this.seleccionar() }
    },
    allCards() {
      if (this.seleccionado != null) {
        this.selected = this.seleccionado
      }
      axios
        .post('', {
          query: `
            query{
              allCards(isLegacy:${this.isLegacy}){
                edges {
                  node {
                    id
                    name
                    width
                    height
                    sampleImage
                  }
                }
              }
            }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.response = res.data.data.allCards.edges
          this.response.forEach(element => {
            if (this.selected != null) {
              if (this.selected.id === element.node.id) {
                this.marcar(element.node)
              }
            }
          })
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style lang="scss" scoped>
#cardSelector #card {
  background-color: aliceblue;
}

#cardSelector .dropContainer {
  border: 3px dashed;
}

#cardSelector .card-width {
  width: 200px;
}

#cardSelector .list-group-item {
  transition: all 1s;
}

#cardSelector option:checked {
  background-color: #ffffff !important;
}

#cardSelector .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
}

#cardSelector #avatar {
  width: 10rem;
  height: 5.5rem;
}

#cardSelector .td {
  width: 50px;
  height: 100px;
}

#cardSelector .folder {
  min-height: 150px;
  height: 300px;
  cursor: pointer;
}

#cardSelector .check {
  background-color: #212736 !important;
}
</style>
